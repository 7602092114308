import Vue from 'vue/dist/vue.esm'
import IntroductionModal from '@/vue_components/modal_windows/introduction/introduction_modal.vue'

// В методе создаётся модальное окно для заглушек
// elementId - элемент, на котором будет смонтирован компонент
// icon - иконка перед текстом модального окна
// innerText - текст модального окна
// titleText - текст заголовка модального окна, если '', то "Модуль не подключен" (+locale)
// button - text + url, текст на кнопке модального окна, если '', то "ПОДКЛЮЧИТЬ МОДУЛЬ" (+locale)
export function introductionModalBuilder (elementId, icon, innerText, titleText = '', button = { text: '', url: '' }) {
  if (!elementId || !icon || !innerText) return null

  // создание блока для монтирования
  $('body').append(`<div id="${elementId}">`)

  // компонент
  return new Vue({
    el: `#${elementId}`,
    components: { IntroductionModal },
    data () {
      return {
        icon,
        innerText,
        titleText,
        button,
      }
    },
    methods: {
      modalShow () {
        this.$refs.modal.modalShow()
      },
      // 7jt1ev (11)
      setData (icon, innerText, titleText = '', button = { text: '', url: '' }) {
        this.icon = icon
        this.innerText = innerText
        this.titleText = titleText
        this.button = button
      },
      setDataAndShow (icon, innerText, titleText = '', button = {}) {
        this.setData(icon, innerText, titleText, button)
        this.modalShow()
      },
    },
    template: `
        <IntroductionModal
          id="${elementId}"
          ref="modal"
          :modal-icon="icon"
          :modal-inner="innerText"
          :modal-title="titleText"
          :modal-button="button"
        />
      `,
  })
}
