import { request } from '@/lib/transport/request'
import {
  companyAdapter,
  companyTypeAdapter,
  companyTypesAdapter,
} from '@/vue_components/companies/adapters'

// TODO: перевести модуль компаний на использование эндпоинта в ближайший рефакторинг
export const companiesEndpoint = {
  create (company) {
    return createCompany(companyAdapter.toServer(company))
  },
  getCompanyTypes () {
    return request({
      type: 'POST',
      url: Routes.catalogs_company_types_list_path(),
      data: companyTypesAdapter.toServer(),
    }, (types) => types.data.map(companyTypeAdapter.toClient)).promise
  },
}

/**
 * @param {{
 *   search_query: string,
 *   limit: number,
 *   offset: number
 * }} params
 * @return {Promise<object[]>}
 */
export const findCompanies = (params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.search_companies_path(),
      data: params,
      success: resolve,
      error: reject,
    })
  })
}

export const fetchCompany = (companyId, params = {}) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.fetch_company_company_path(companyId),
      data: params,
      success () {
        resolve(arguments)
      },
      error: reject,
    })
  })
}

export const createCompany = (params) => {
  return request({
    type: 'POST',
    url: Routes.companies_path(),
    data: { company: params },
  }).promise
}

export const updateCompany = (companyId, params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'PATCH',
      url: Routes.company_path(companyId),
      data: { company: params },
      success: resolve,
      error: reject,
    })
  })
}

export const deleteCompany = (companyId) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'DELETE',
      url: Routes.company_path(companyId),
      success: resolve,
      error: reject,
    })
  })
}

export const fetchCompanyTypes = (params = {}) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: Routes.catalogs_company_types_list_path(),
      data: {
        ...params,
        ...companyTypesAdapter.toServer(),
      },
      success: resolve,
      error: reject,
    })
  })
}

export const fetchOrders = (companyId, params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.fetch_company_orders_company_path(companyId),
      data: params,
      success: resolve,
      error: reject,
    })
  })
}

export const findOrdersRegistries = (companyId, params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.search_for_company_orders_registries_company_path(companyId),
      data: params,
      success: resolve,
      error: reject,
    })
  })
}

export const checkOrdersInRegistry = (registryId) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.orders_count_orders_registry_path(registryId),
      success: resolve,
      error: reject,
    })
  })
}

export const fetchOrdersRegistry = (companyId, registryId) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: Routes.fetch_orders_registry_company_path(companyId, registryId),
      success: resolve,
      error: reject,
    })
  })
}

export const createOrderRegistry = (params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: Routes.orders_registries_path(),
      data: { orders_registry: params },
      success: resolve,
      error: reject,
    })
  })
}

export const updateOrdersRegistry = (registryId, attributes) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'PATCH',
      url: Routes.orders_registry_path(registryId),
      data: { orders_registry: attributes },
      success: resolve,
      error: reject,
    })
  })
}

export const updateOrdersRegistryId = (registryId, orderIds) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'PATCH',
      url: Routes.update_registry_id_orders_path(),
      data: { orders_registry_id: registryId, order_ids: orderIds },
      success: resolve,
      error: reject,
    })
  })
}

export const deleteOrdersRegistry = (registryId) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'DELETE',
      url: Routes.orders_registry_path(registryId),
      success: resolve,
      error: reject,
    })
  })
}

export const fetchPayments = (companyId, params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.fetch_payments_company_path(companyId),
      data: params,
      success: resolve,
      error: reject,
    })
  })
}

export const createPayment = (params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: Routes.payments_path(),
      data: params,
      success: resolve,
      error: reject,
    })
  })
}

export const createPayments = (params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: Routes.mass_pay_payments_path(),
      data: params,
      success: resolve,
      error: reject,
    })
  })
}
