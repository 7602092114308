import { clientAdapter, clientSearchSelect2, clientSummaryAdapter } from '@/api_entities/client/clients_adapters'
import { request } from '@/lib/transport/request'
import formatter from '@/lib/formatters/formatter'

/**
 * @typedef {{
 *   client: { id: number },
 *   date: string,
 *   user_id?: number
 * }} GetSummaryParams
 *
 */

export const clientEndpoint = {
  get (id) {
    const options = {
      type: 'get',
      url: Routes.find_client_path(id),
    }

    return request(
      options,
      (resp) => {
        const clientData = {
          ...clientAdapter.toClient(resp),
          id: resp.id,
        }

        return { ...clientData, short_info: formatter.formatValue(clientData, ['client:shortInfo']) }
      })
      .promise
  },

  create (formData) {
    return request({
      type: 'POST',
      url: Routes.lazy_create_clients_path(),
      data: { client: clientAdapter.toServer(formData) },
    }).promise
  },

  update (clientId, formData) {
    return request({
      type: 'PATCH',
      url: Routes.client_path(clientId),
      data: { client: clientAdapter.toServer(formData) },
    }).promise
  },

  /**
   * @param {GetSummaryParams} params
   * @return {Promise<*>}
   */
  getSummary (params) {
    return request({
      type: 'POST',
      url: Routes.get_summary_clients_path(),
      data: params,
    }, clientSummaryAdapter.toClient).promise
  },

  searchSelect2 (searchString) {
    const options = {
      type: 'GET',
      url: Routes.search_select2_clients_path({ term: searchString }),
    }

    return request(options, clientSearchSelect2.toClient).promise
  },
}
