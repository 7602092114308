export const APPOINTMENT_STATUS_NUMBERS = Object.freeze({
  'approved': 1,
  'need_approval': 2,
  'billed': 3,
  'canceled': 4,
  'failed': 5,
  'arrived': 6,
  'serviced': 7,
  'paid': 8,
  'confirmed_by_administrator': 9,
})
