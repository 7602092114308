import { createAppointment, updateAppointment } from '../../vue_components/doctor_schedules/rest.js'
import { appointmentAdapter } from '@/vue_components/doctor_schedules/adapters'

export const appointmentsEndpoint = {
  create (formData) {
    return createAppointment({ appointment: appointmentAdapter.toServer(formData) })
  },

  update (formData) {
    return updateAppointment(formData.appointmentId, {
      appointment: appointmentAdapter.toServer(formData)
    })
  },
}
