<template>
  <span :class="className">
    {{ dateString }}
  </span>
</template>
<script>
export default {
  name: 'FriendlyDate',
  props: {
    /**
     * `date` prop should be in format, defined in `format` prop
     */
    date: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    toFormat: {
      type: String,
      default: 'ddd, DD MMMM',
    },
  },
  computed: {
    dateInfo () {
      return Utils.formatClosestDates(this.date, this.toFormat, this.format)
    },
    className () {
      return this.dateInfo.className
    },
    dateString () {
      return this.dateInfo.title
    },
  },
}
</script>
