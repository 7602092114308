<template>
  <div>
    <basic-tooltip
      v-show="showLogo"
      :text="clinicName"
      :show-tooltip="showTooltip"
    >
      <img
        :src="logoPath"
        class="logo-clinics-in-table"
        alt="logo"
        @error="showLogo = false"
      >
    </basic-tooltip>
    <basic-tooltip
      v-show="!showLogo"
      :text="clinicName"
      :show-tooltip="showTooltip"
    >
      <span class="clinic-name-in-table">{{ clinicName }}</span>
    </basic-tooltip>
  </div>
</template>

<script>
import BasicTooltip from '@/vue_components/common/basic_tooltip'

export default {
  name: 'ClinicLogo',

  components: { BasicTooltip },

  props: {
    clinicId: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      showLogo: true,
    }
  },

  computed: {
    logoPath () {
      return Routes.logo_clinic_path(this.clinicId)
    },
    clinicName () {
      const currentClinic = gon.application.current_user_clinics.find((el) => el.id === this.clinicId)

      return currentClinic ? currentClinic.title : ''
    },
    showTooltip () {
      return !!this.clinicName
    },
  },
}
</script>
