const isOverlapingIntervals = (target, interval) => {
  return target[0] <= interval[1] && interval[0] <= target[1]
}

const uniteIntervals = (a, b) => {
  const start = a[0] < b[0] ? a[0] : b[0]
  const end = a[1] > b[1] ? a[1] : b[1]

  return [start, end]
}

const mergeIntervals = (intervals) => {
  const result = []
  const sortedIntervals = intervals.sort((a, b) => a[0] - b[0])
  result.push(sortedIntervals[0])

  for (let i = 1; i < sortedIntervals.length; i++) {
    let found = false
    for (let j = 0; j < result.length; j++) {
      if (isOverlapingIntervals(sortedIntervals[i], result[j])) {
        result[j] = uniteIntervals(sortedIntervals[i], result[j])
        found = true
      }
    }
    if (!found) result.push(sortedIntervals[i])
  }

  return result
}

/**
 * @param workTimes { Array } - [[start, end], [start, end], ...]
 * @param appointmentTime { String }
 * @returns { Number }
 */
export function getAvailableDuration (workTimes, appointmentTime) {
  const start = moment.utc(appointmentTime, 'HH:mm')

  const mergedIntervals = mergeIntervals(workTimes)
  const endRange = mergedIntervals.length > 1
    ? mergedIntervals.find((range) => start.isBetween(moment.utc(range[0], 'HH:mm'), moment.utc(range[1], 'HH:mm')) || start.isSame(moment.utc(range[0], 'HH:mm')))
    : mergedIntervals[0]

  const end = moment.utc(endRange[1], 'HH:mm')

  return moment.duration(end.diff(start), 'milliseconds').asMinutes()
}
