import { request } from '@/lib/transport/request'

export const reminderEndpoint = {
  destroy (id) {
    return request({
      type: 'DELETE',
      url: Routes.reminder_path(id),
    }).promise
  },
}
