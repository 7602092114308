<template>
  <span class="appointment-status">
    <span :class="iconClass" />
    {{ statusText }}
  </span>
</template>

<script>
export default {
  name: 'AppointmentStatus',
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      // statuses, from 1 to 9
      statuses: [
        {}, //костыль т.к. нет статуса с enum соответствующим 0
        { // approved
          icon: 'appointment-status-icon fad fa-fw fa-circle',
          title: T.enums.appointment.status.approved,
        }, {// need_approval
          icon: 'appointment-status-icon fad fa-fw fa-fw fa-circle-notch',
          title: T.enums.appointment.status.need_approval,
        }, {// billed
          icon: 'appointment-status-icon fad fa-fw fa-credit-card',
          title: T.enums.appointment.status.billed,
        }, {// canceled
          icon: 'appointment-status-icon fad fa-fw fa-times',
          title: T.enums.appointment.status.canceled,
        }, {// failed
          icon: 'appointment-status-icon fad fa-fw fa-user-times',
          title: T.enums.appointment.status.failed,
        }, {// arrived
          icon: 'appointment-status-icon fad fa-fw fa-user',
          title: T.enums.appointment.status.arrived,
        }, {// serviced
          icon: 'appointment-status-icon fad fa-fw fa-clipboard-check',
          title: T.enums.appointment.status.serviced,
        }, {// paid
          icon: 'appointment-status-icon fad fa-fw fa-check-square',
          title: T.enums.appointment.status.paid,
        }, {// confirmed_by_administrator
          icon: 'appointment-status-icon fad fa-fw fa-thumbs-up',
          title: T.enums.appointment.status.confirmed_by_administrator,
        },
      ],
    }
  },

  computed: {
    iconState () {
      return this.statuses[this.status] || {}
    },
    statusText () {
      return this.iconState.title || ''
    },
    iconClass () {
      return this.iconState.icon || ''
    },
  },
}
</script>

<style scoped>

</style>
