<template>
  <div
    ref="lastAppointments"
    class="last-appointments"
    tabindex="0"
  >
    <table class="show_info_table">
      <tbody>
        <tr>
          <td>{{ t('full_name') }}</td>
          <td>
            {{ client.fullname }}
            <span class="hidden-print">
              /<a
                :href="client.id | route('client_path')"
                target="_blank"
              >
                <i class="fad fa-fw fa-user" />
                {{ t('infocard') }}
              </a>
            </span>
            <span class="hidden-print">
              /<a
                :href="client.medical_record_id | route('medical_record_path')"
                target="_blank"
              >
                <i
                  id="medcard_link"
                  class="fad fa-fw fa-id-card-alt"
                />
                {{ t('medcard') }}
              </a>
            </span>
          </td>
        </tr>
        <tr>
          <td>{{ t('parent_name') }}</td>
          <td>{{ legalRepresentativesFullnamesText }}</td>
        </tr>
        <tr>
          <td>{{ t('phone') }}</td>
          <td>
            <span class="phone_number callable">
              <phone
                class="ignore-click"
                :raw-phone="client.phone"
                :surrogate="t('absence')"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>{{ t('birthdate') }}</td>
          <td v-if="client.birthdate">
            {{ client.birthdate | formatDate(GET_LOCALIZATION_DATE_FORMAT) }}
          </td>
          <td v-else>
            {{ t('birthdate_is_null') }}
          </td>
        </tr>
        <tr>
          <td>{{ t('emk') }}</td>
          <td>{{ client.emk_number }}</td>
        </tr>
        <!-- Медицинский полис-->
        <tr>
          <td>{{ t('medical_policy') }}</td>
          <td>{{ hasActiveMedicalPolicyText }}</td>
        </tr>
      </tbody>
    </table>
    <guarded-control
      tag="button"
      :permissions="['canManageAppointment']"
      hide-tooltip
      class="add_appointment btn btn-primary btn-sm"
      @click:allowed="create(client)"
    >
      <i class="fad fa-fw fa-calendar-day" />
      {{ t('make_appointment') }}
    </guarded-control>
    <si-table>
      <template #header>
        <si-field
          :checked="checkAll"
          @checkbox-change="toggleAll($event.target.checked)"
        >
          <div class="date">
            {{ t('custom_date') }}
          </div>
          <div class="date">
            {{ t('custom_time') }}
          </div>
          <div class="datetime">
            {{ t('duration') }}
          </div>
          <div
            v-if="needClinicInfo"
            class="clinics-logo-field"
          >
            {{ t('clinic') }}
          </div>
          <div class="appointment-status">
            {{ t('status') }}
          </div>
          <div class="short_name">
            {{ t('doctor') }}
          </div>
          <div class="specialty">
            {{ t('specialty') }}
          </div>
          <div class="appointment-orders">
            {{ t('order') }}
          </div>
          <div />
        </si-field>
      </template>
      <template #body>
        <si-field
          v-for="row in appointments"
          :key="row.id"
          :checked="selectedAppointments.includes(row.id)"
          :disabled="row.disabled || !canCreateOrder(row)"
          @checkbox-change="onAppointmentSelect(row.id, $event.target.checked)"
        >
          <div class="date">
            <friendly-date
              :date="row.date"
              :to-format="GET_LOCALIZATION_DATE_FORMAT"
            />
          </div>
          <div class="date">
            {{ row.time }}
          </div>
          <div class="datetime">
            {{ row.duration }}
          </div>
          <div
            v-if="needClinicInfo"
            class="clinics-logo-field"
          >
            <clinic-logo :clinic-id="row.clinic_id" />
          </div>
          <div class="appointment-status cut">
            <appointment-status
              :status="APPOINTMENT_STATUS_NUMBERS[row.status]"
            />
          </div>
          <div class="short_name cut">
            {{ row.user.full_name }}
          </div>
          <div class="specialty cut">
            {{ row.user.specialties_titles }}
          </div>
          <div class="appointment-orders cut">
            <guarded-control
              v-if="hasOrder(row)"
              tag="a"
              :permissions="['canViewOrder']"
              :href="row.order_id | route('order_path')"
              class="btn btn-link btn-xs appointment-create-order"
            >
              {{ t('reception.go_to_order') }}
            </guarded-control>
            <guarded-control
              v-if="canCreateOrder(row)"
              tag="a"
              :permissions="['canManageOrder']"
              :href="newOrderParams([row.id]) | route('new_order_path')"
              class="btn btn-link btn-xs appointment-create-order"
            >
              {{ t('add_order') }}
            </guarded-control>
          </div>
          <div class="schedule-grid-appointment-btn-container">
            <guarded-control
              tag="button"
              :permissions="['canManageAppointment']"
              :tooltip="t('move')"
              class="btn btn-primary btn-xs schedule-grid-appointment-move tooltip-bottom"
              @click:allowed="move(row)"
            >
              <i class="fad fa-fw fa-exchange" />
            </guarded-control>
            <guarded-control
              tag="button"
              :permissions="['canManageAppointment']"
              :tooltip="t('edit')"
              class="btn btn-warning btn-xs schedule-grid-appointment-edit tooltip-bottom"
              @click:allowed="edit(row)"
            >
              <i class="fad fa-fw fa-pencil" />
            </guarded-control>
            <guarded-control
              tag="button"
              :permissions="['canManageAppointment']"
              :tooltip="t('cancel')"
              class="btn btn-grey btn-xs schedule-grid-appointment-cancel tooltip-bottom"
              @click:allowed="$emit('cancel', row)"
            >
              <i class="fad fa-fw fa-times" />
            </guarded-control>
          </div>
        </si-field>
      </template>
    </si-table>
  </div>
</template>

<script>
import AppointmentStatus from '@/vue_components/appointment_common/appointment_status.vue'
import Phone from '@/vue_components/common/phone.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'

import WithTooltips from '@/vue_components/mixins/withTooltips.js'
import { APPOINTMENT_STATUS_NUMBERS } from '@/vue_components/appointment_common/const.js'
import GuardedControl from '@/vue_components/common/guarded_control'
import { dateFormatter } from '@/vue_components/mixins/formatters'
import {
  APPOINTMENT_STATUS,
  EMPTY_VALUE_TEXT,
} from '@/vue_components/appointment/const'
import FriendlyDate from '@/vue_components/common/friendly_date'
import ClinicLogo from '@/vue_components/clinic/clinic_logo'
import { clientGetSummary } from '@/vue_components/doctor_schedules/doctor_schedules_methods'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { vueFilters } from 'src/plugins/vue/filtersPlugin/vueFilters'

/**
 * @event LastAppointments#edit
 * @type {Number}
 *
 * @event LastAppointments#move
 * @type {Number}
 *
 * @event LastAppointments#cancel
 * @type {Number}
 *
 * @event LastAppointments#select
 * @type {Number[]}
 */
export default {
  name: 'LastAppointments',

  components: { FriendlyDate, GuardedControl, Phone, SiField, SiTable, AppointmentStatus, ClinicLogo },

  mixins: [WithTooltips, dateFormatter],

  props: {
    client: {
      type: Object,
      required: true,
    },

    appointments: {
      type: Array,
      required: true,
    },

    selectedAppointments: PropsTypes.Array(),
    needClinicInfo: Boolean,
  },

  data () {
    return {
      clientSummary: {},
      APPOINTMENT_STATUS_NUMBERS,
    }
  },

  computed: {
    GET_LOCALIZATION_DATE_FORMAT () {
      return Utils.momentDateFormat
    },

    activeAppointments () {
      return this.appointments.filter((appointment) => {
        return !appointment.disabled && this.canCreateOrder(appointment)
      })
    },

    checkAll () {
      return this.activeAppointments.length === this.selectedAppointments.length
    },

    hasActiveMedicalPolicyText () {
      return this.clientSummary.activeMedicalPoliciesTitles || EMPTY_VALUE_TEXT
    },

    legalRepresentativesFullnamesText () {
      return this.client?.legal_representatives
        ?.map(vueFilters.fullName)
        .join(', ') || EMPTY_VALUE_TEXT
    },
  },

  mounted () {
    this.fetchClientSummary()
    this.updateTooltips()
    this.$refs.lastAppointments.focus()
  },

  methods: {
    async fetchClientSummary () {
      try {
        const params = {
          client: { id: this.client.id },
          date: Utils.getBaseFormattedDate(new Date()),
        }

        this.clientSummary = await clientGetSummary(params)
      } catch (err) {
        this.clientSummary = {}
      }
    },

    onAppointmentSelect (appointmentId, checked) {
      const newChecked = checked
        ? this.selectedAppointments.concat(appointmentId)
        : this.selectedAppointments.filter((id) => id !== appointmentId)
      this.$updateSync('selectedAppointments', newChecked)
    },

    /**
     * @param {number[]} appointmentIds
     * @return {{appointment_ids: number[], client_id: number}}
     */
    newOrderParams (appointmentIds) {
      return {
        appointment_ids: appointmentIds,
        client_id: this.client.id,
      }
    },

    toggleAll (checked) {
      const checkedRows = checked
        ? this.activeAppointments.map((app) => app.id)
        : []
      this.$updateSync('selectedAppointments', checkedRows)
    },

    hasOrder (appointment) {
      return appointment.order_id > 0
    },

    canCreateOrder (appointment) {
      return !this.hasOrder(appointment) &&
        appointment.status !== APPOINTMENT_STATUS.CANCELED
    },

    //region appointment operations
    // копипаста ради читаемости в шаблоне и возможности изменения действий по отдельности
    edit (appointment) {
      this.$emit('edit', appointment)
    },

    move (appointment) {
      this.$emit('close-modal')
      this.$emit('move', appointment)
    },

    create (appointment) {
      this.$emit('close-modal')
      this.$emit('create', appointment)
    },
    //endregion
  },
}
</script>
