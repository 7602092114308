import { getRegistrationDate } from '@/vue_components/companies/helpers/get_registration_date'
import { nameToCapitalize } from '@/vue_components/companies/helpers/name_to_capitalize'

export const companyAdapter = {
  toServer (company) {
    return {
      ...company,
      registration_date: getRegistrationDate(company.registration_date),
      head_name: nameToCapitalize(company.head_name),
      head_surname: nameToCapitalize(company.head_surname),
      head_second_name: nameToCapitalize(company.head_second_name),
    }
  },
}

export const companyTypeAdapter = {
  toClient (companyType) {
    return {
      id: companyType.id,
      title: companyType.title,
    }
  },
}

export const companyTypesAdapter = {
  toServer () {
    return {
      sort_params: {
        offset: 0,
        limit: 2 ** 25,
      },
    }
  },
}
