<template>
  <basic-tooltip
    :text="tooltipText"
    :show-tooltip="showTooltip"
  >
    <component
      :is="tag"
      class="guarded-control"
      :class="{ [disabledClass]: disabled }"
      v-bind="$attrs"
      @click="onClick"
      v-on="listeners"
    >
      <slot />
    </component>
  </basic-tooltip>
</template>

<script>
import BasicTooltip from '@/vue_components/common/basic_tooltip'
import { permissionGuard } from '@/vue_components/mixins/permission_guard'

/**
 * Компонент используется для того, чтобы ограничить возможности пользователя,
 * исходя из его прав. Может заменять ссылки и кнопки.
 *
 * guarded-control позволяет подписываться на DOM-события с предварительной
 * проверкой прав, указанных в permissions. Есть три варианта выпускаемых событий:
 *  - `click` - обычное событие, выпускается самим DOM-элементом;
 *  - `click:allowed` - событие, выпускаемое guarded-control, если при событии
 *    `click` проверка прав была пройдена;
 *  - `click:not-allowed` - противоположно предыдущему; событие будет выпущено,
 *     если проверка прав не была пройдена.
 * Вместо `click` может быть любое другое событие, причём не обязательно событие
 * DOM-элемента, так как guarded-control может оборачивать и другие компоненты.
 *
 * В случае, когда guarded-control является кнопкой и её нужно сделать disabled,
 * есть два варианта, как это сделать:
 *  - если тултип должен работать на ней, то нужно использовать класс `disabled`
 *  - если тултип не нужен, то можно использовать атрибут `disabled`.
 *
 * Помимо этого компонента, для работы с правами есть guarded-view. Он отличается
 * тем, что он оборачивает компонент и не рендерит его вообще, если проверка прав
 * не пройдена.
 */
export default {
  name: 'GuardedControl',

  components: { BasicTooltip },

  mixins: [permissionGuard],

  props: {
    tag: {
      type: String,
      required: true,
    },
    disabledClass: {
      type: String,
      default: 'disabled',
    },
    hideTooltip: {
      type: Boolean,
    },
    /**
     * Текст тултипа, который должен быть показан всегда.
     * Он будет дополнен текстом предупреждения о правах,
     * если проверка прав не пройдена.
     */
    tooltip: {
      type: String,
      default: undefined,
    },
    /**
     * Текст предупреждения о правах
     */
    permissionWarning: {
      type: String,
      default: t('insufficient_access_rights'),
    },
  },

  computed: {
    listeners () {
      const ALLOWED = ':allowed'
      const NOT_ALLOWED = ':not-allowed'
      const listeners = _.omit(this.$listeners, [
        'click',
        `click${ALLOWED}`,
        `click${NOT_ALLOWED}`,
      ])

      Object.keys(listeners).forEach((key) => {
        [ALLOWED, NOT_ALLOWED].forEach((suffix) => {
          if (key.includes(suffix)) {
            const originalListener = listeners[key]
            delete listeners[key]

            listeners[key.slice(0, -suffix.length)] = (event) => {
              if (suffix === ALLOWED ? !this.disabled : this.disabled) {
                originalListener(event)
              }
            }
          }
        })
      })

      return listeners
    },
    tooltipText () {
      let text = ''

      if (this.tooltip) {
        text += `${this.tooltip}<br>`
      }

      if (this.disabled) {
        text += this.permissionWarning
      }

      return text
    },
    showTooltip () {
      return !this.hideTooltip && !!this.tooltipText
    },
  },

  methods: {
    onClick (event) {
      if (this.disabled) {
        if (this.tag === 'a') {
          event.preventDefault()
        }

        this.$emit('click:not-allowed', event)
      } else {
        this.$emit('click:allowed', event)
      }

      this.$emit('click', event)
    },
  },
}
</script>
