export const permissionGuard = {
  props: {
    /**
     * Массив прав, которые будут проверяться через `Services.security`
     *
     * @type {import('vue').PropOptions<string[]>}
     */
    permissions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    disabled () {
      return this.permissions.some((permission) => !this.$security[permission])
    },
  },
}
